






























































































import { ElForm } from 'element-ui/types/form';
import { Component, Vue } from 'vue-property-decorator';
import { getTenantData } from '@/serve/medicalAppointment/institutionManage';
import { getDynamicSatisfaction } from '@/serve/evaluatingBefore/evaluatingQuestion';


interface DiseaseStandardList {
  comment: string;
  highestScore: number | string;
  lowestScore: number | string;
  remark: string;
  riskLevel: string;
  [index: string]: any;
}
interface StandarData {
  size?: number;
  current?: number;
  diseaseName?: string;
  [propName: string]: any;
}
@Component({
  name: 'EvaluatingStandard',
  filters: {},
})
export default class EvaluatingStandard extends Vue {
  public currentPage4 = 1;

  public formLabelWidth = '120px';
  public form = {
    illness: '',
    category: '',
  };

  public checkSignId = 0;



  public activeName = '低';






  public tableData = [];
  public switchValue = false
  public dialogVisible = false
  public answerRecommendation = ""


  public formDatas = {
    size: 10,
    current: 1,
    name: '',
    phone: '',
    isSatisfied: true
  };
  public tenantName = '';
  public restaurants = [];
  public isAdmin = sessionStorage.getItem('isAdmin');
  public created() {
    this.getDiseaseList();
  }
  public mounted() {
    this.getTenantData();
  }
  // 进入页面获取数据
  public async getDiseaseList() {

    getDynamicSatisfaction(this.formDatas).then(res=>{
      console.log('获得数据',res)
      this.tableData = res.data
      console.log('获得数据',this.tableData)
    })
  //   const tableDataNew = res.data
  //   if (tableDataNew.records.length) {
  //     tableDataNew.records.map((item: { answerFirst: any; answerSecond: any; answerThird: any }) => {
  //       item.answerFirst = JSON.parse(item.answerFirst)
  //       item.answerSecond = JSON.parse(item.answerSecond)
  //       item.answerThird = JSON.parse(item.answerThird)

  //     })
  //   }
  }

  public switchChange(e: any) {

    if (e) {
      this.formDatas.isSatisfied = false;
      this.getDiseaseList();
    } else {
      this.formDatas.isSatisfied = true;
      this.getDiseaseList();
    }

  }
  public editRow(e: any) {
    this.dialogVisible = true
    console.log('e',e)
    this.answerRecommendation = e.content
  }


  /**获取全部租户 */
  public async getTenantData(): Promise<void> {
    const res = await getTenantData({ size: 100000, current: 1 });
    this.restaurants = res.data.records;
  }
  

  public async querySearch(queryString: any, cb: any) {
    const res = await getTenantData({
      size: 100,
      current: 1,
      name: queryString,
    });
    const restaurants = res.data.records;
    const newArr: any = [];
    restaurants.forEach((x: any) => {
      if (x.name.includes(queryString)) {
        newArr.push({ value: x.name, tId: x.id });
      }
    });
    // 调用 callback 返回建议列表的数据
    cb(newArr);
  }
  // 筛选按钮
  public onSubmit(): void {
    this.getDiseaseList();
  }

  // 点击左右箭头
  public handleSizeChange(val: any): void {
    this.formDatas.size = val;
    this.getDiseaseList();
  }
  // 点击页码
  public handleCurrentChange(val: any): void {
    this.formDatas.current = val;
    this.getDiseaseList();
  }
  // 重置按钮
  public resetForm(formName: string) {
    (this.$refs[formName] as ElForm).resetFields();

    this.formDatas.name = '';
    this.formDatas.phone = '';
    this.getDiseaseList();
  }
  
}
